
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
     <Routes>
       <Route path="/" element={<App lang='de' />} />
       <Route path="en" element={<App lang='en' />} />
       <Route path="de" element={<App  lang='de' />} />
     </Routes>
   </BrowserRouter>
);
