import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import headingId from 'remark-heading-id';
import ImageGallery from 'react-image-gallery';

import './index.css';
import german from './data/german.md';
import english from './data/english.md';

import maps from './assets/icons/location.svg';
import facebook from './assets/icons/facebook.svg';
import instagram from './assets/icons/instagram.svg';
import tripAdvisor from './assets/icons/tripAdvisor.svg';

import Header from './components/Header';

const foodImg = [...Array(10)].map((_, i) =>
({
  original: require(`./assets/img/food/dreistuben_food_${i + 1}.jpg`),
})
);

const interiorImg = [...Array(6)].map((_, i) =>
({
  original: require(`./assets/img/interior/dreistuben_interior_${i + 1}.jpg`),
})
);

const App = ({ lang }) => {
  const [text, set] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const url = lang == 'en' ? english : german
    fetch(url).then(res => res.text()).then(md => {
      const data = md.split('<!-- section -->');
      data.shift();
      set(data);
    })
  }, [lang])

  const options = {
    showNav: false,
    showPlayButton: false,
    autoPlay: true,
    showThumbnails: false,
    showFullscreenButton: false,
    showBullets: true,
    additionalClass: 'gallery',
    slideInterval: 5000,
  }

  return (
    <>
      <Header navigation={text[0]} />
      <main>
        <section className='lead'>
          <div>
            <ReactMarkdown children={text[1]} remarkPlugins={[headingId]} />
          </div>
        </section>
        <section className='food'>
          <div>
            <ReactMarkdown children={text[2]} remarkPlugins={[headingId]} />
          </div>
          <div className='gallery'>
            <ImageGallery items={foodImg} {...options} />
          </div>
        </section>
        <section className='events'>
          <div className='gallery'>
            <ImageGallery items={interiorImg} {...options} />
          </div>
          <div>
            <ReactMarkdown children={text[3]} remarkPlugins={[headingId]} />
          </div>
        </section>
        <section className='info'>
          <div>
            <ReactMarkdown children={text[4]} remarkPlugins={[headingId]} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexGrow: 1 }}>
              <ReactMarkdown children={text[5]} remarkPlugins={[headingId]} />
            </div>
            <div className="icons">
              <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Restaurant+Drei+Stuben/@47.3832743,8.5416094,17z/data=!4m5!3m4!1s0x0:0xc706be47f71f4560!8m2!3d47.3832743!4d8.5416094"><img src={maps} className="icon" alt="Google Maps" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/drei_stuben/"><img src={instagram} className="icon" alt="Instagram" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/dreistuben"><img src={facebook} className="icon" alt="Facebook" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.tripadvisor.ch/Restaurant_Review-g188113-d2446209-Reviews-Drei_Stuben-Zurich.html"><img src={tripAdvisor} className="icon" alt="Trip Advisor" /></a>
              <div className="links" style={{ visibility: 'hidden' }}>
                <a href="/terms">TERMS</a>
                <a href="/privacy">PRIVACY</a>
                <a href="/impressum">IMPRESSUM</a>
              </div>
              <div className="copyright">© {currentYear} Drei Stuben</div>
            </div>
          </div>
        </section>

      </main>
    </>
  )
}

export default App;
